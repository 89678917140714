import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import { Login, SubTitle, InputBox, BtnBox, Form } from './styled'
import { translate } from '../../utils/translate';

const Component = ({ formik, messages, data }) => {
  return (
    <Login>
      <h1>{translate('pages.RecoverPassword.forgotPassword')}</h1>
      <SubTitle>{translate('pages.RecoverPassword.instruction')}</SubTitle>
      <Form onSubmit={formik.handleSubmit}>
        <Messages formMessages={messages.messages} alert={messages.alert} />
        <InputBox>
          <Input
            labelColor="var(--color-fff)"
            type="password"
            name="password"
            label={translate('pages.RecoverPassword.password')}
            formik={formik}
          />
        </InputBox>
        <InputBox>
          <Input
            labelColor="var(--color-fff)"
            type="password"
            name="password_confirmation"
            label={translate('pages.RecoverPassword.confirm')}
            formik={formik}
          />
        </InputBox>
        <BtnBox>
          <BtnBlue style={{ backgroundColor: data ? data.secondary_color  : "var(--color-yellow)" }} type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <Spinner /> : translate('pages.RecoverPassword.submit')}
          </BtnBlue>
        </BtnBox>
      </Form>
    </Login>
  )
}

export default Component