import ptBR from './locales/pt-BR.json';
import en from './locales/en.json';
import es from './locales/es.json';
import ja from './locales/ja.json';

const languages = {
  'pt-BR': ptBR,
  'en': en,
  'es': es,
  'ja': ja
};

const loadLanguage = () => {
  const language = localStorage.getItem('language') || 'pt-BR';
  return language;
};

export const setLanguage = (lang) => {
  localStorage.setItem('language', lang);
  window.location.reload();
};

const getNestedTranslation = (translations, key) => {
  return key.split('.').reduce((obj, k) => (obj && obj[k] !== undefined ? obj[k] : null), translations);
};

export const translate = (key, params = {}) => {
  const language = loadLanguage();
  const translations = languages[language] || languages['pt-BR'];
  let translation = getNestedTranslation(translations, key) || key;

  Object.keys(params).forEach((param) => {
    translation = translation.replace(`{{${param}}}`, params[param]);
  });

  return translation;
};
