import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import DatePicker from 'components/Form/DatePicker'
import Table from 'components/Table'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import api from 'services/api/api'

import { handleUserShow } from 'services/api/users'

import { setErrors } from 'utils'
import { translate } from '../../../utils/translate';

const validation = yup => ({
    // reports: yup
    //   .array()
    //   .min(1, 'Campo obrigatório'),
})

const NewUserReportsCollection = () => {
    const [user, setUser] = useState({})

    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()
    const params = useParams()

    const formik = useFormik({
      initialValues: {
        initial_date: null,
        end_date: null
      },
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => {
        const initial_date = formik.values.initial_date.toISOString()
        const end_date = formik.values.end_date.toISOString()

        console.log(initial_date)
        // return

        try {
          await api.post(`/users-access`, {
            initial_date,
            end_date,
            user_id: user.id
          })

          history.push(`/usuarios/${user.id}/acessos`)
        } catch (error) {
          setMessages({ messages: setErrors(error), alert: 'alert-orange' })
        }
      }
    })

    useEffect(() => {
        (async () => {
            setUser(await handleUserShow(params.id))
        })()
    }, [params.id])

    function getUpcomingSunday() {
      const date = new Date()

      const today = date.getDate()
      const currentDay = date.getDay()

      const newDate = date.setDate(today - currentDay + 6)

      return new Date(newDate)
    }

    function getLastSunday() {
      const date = new Date()

      const currentDay = date.getDay()

      if (currentDay === 0) return date

      const newDate = date.setDate(date.getDate() - (currentDay || 7))

      return new Date(newDate)
    }

    return (
        <>
        <TitleBar label={translate('pages.UserAccessControlNew.users')} currentPage="" />

        <Content>
          <Card>
            <CardTitle title={translate('pages.UserAccessControlNew.user')} />

            <Table>
              <thead>
                <tr>
                  <th>{translate('pages.UserAccessControlNew.name')}</th>
                  <th>{translate('pages.UserAccessControlNew.email')}</th>
                  <th>{translate('pages.UserAccessControlNew.permission')}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.roles}</td>
                </tr>
              </tbody>
            </Table>
          </Card>

          <Card>
            <CardTitle title={translate('pages.UserAccessControlNew.create')}></CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <DatePicker 
                    showTime 
                    coolFormat
                    maxDate={getLastSunday()} 
                    minDate={getUpcomingSunday()}  
                    name="initial_date" 
                    label={translate('pages.UserAccessControlNew.from')}
                    formik={formik} 
                  />
                  
                  <DatePicker 
                    showTime 
                    coolFormat
                    maxDate={getLastSunday()} 
                    minDate={getUpcomingSunday()}  
                    name="end_date" 
                    label={translate('pages.UserAccessControlNew.to')}
                    formik={formik} 
                  />
                  
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : translate('pages.UserAccessControlNew.submit')}
                  </BtnBlue>
                </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewUserReportsCollection