import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { translate } from '../../utils/translate';

const Component = props => {
  return (
    <>
      <TitleBar label={`${translate('pages.CapacitiesUpdate.capacity')} - ${props.id}`} currentPage="" />      
      <Content>
        <Card>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input
                  name="id"
                  label={translate('pages.CapacitiesUpdate.id')}
                  readOnly
                  disabled
                  value={props.data?.capacity_id}
                  formik={props.formik}
                />
                <Input
                  name="name"
                  label={translate('pages.CapacitiesUpdate.name')}
                  readOnly
                  value={props.data?.name}
                  formik={props.formik}
                />
                <Input
                  name="sku"
                  label={translate('pages.CapacitiesUpdate.sku')}
                  readOnly
                  value={props.data?.sku}
                  formik={props.formik}
                />
                <Input
                  name="status"
                  label={translate('pages.CapacitiesUpdate.status')}
                  readOnly
                  value={props.data?.state}
                  formik={props.formik}
                />
                <Input
                  name="resource_group"
                  label={translate('pages.CapacitiesUpdate.resourceGroupName')}
                  formik={props.formik}
                />
                <Input
                  name="subscription_id"
                  label={translate('pages.CapacitiesUpdate.subscriptionId')}
                  formik={props.formik}
                />
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : translate('pages.CapacitiesUpdate.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Component
