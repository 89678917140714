import { Context } from 'contexts/context'
import { useContext, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { logout } from 'services/auth'

import { BeautifulName } from 'beautiful-name'

import BtnOrange from 'components/Button/BtnOrange'

import { AiOutlineMenu, AiOutlineUser } from 'react-icons/ai'
import { BiLogOut } from 'react-icons/bi'
import {
  FaBusinessTime,
  FaChartArea,
  FaHome,
  FaImages,
  FaIndustry,
  FaLayerGroup,
  FaObjectUngroup,
  FaDatabase,
  FaUsers,
} from 'react-icons/fa'

import {
  ActionsContainer,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownText,
  DropdownToggle,
  LeftSideContainer,
  Logo,
  NavigationContainer,
  NavigationLink,
  NavigationText,
  RightSideContainer,
  ShowMobile,
  Topbar,
  UserContainer,
  UserName,
  UserPhoto,
} from './styled'

import api from '../../services/api/api'

import { translate, setLanguage } from '../../utils/translate';


import {
  handleUpdateLanguagePreference
} from 'services/api/users'

const Component = props => {
  const history = useHistory()

  const { user, report, isLoaded } = useContext(Context)

  const userName = new BeautifulName(user.name).firstName
  const userImage = user.file_url
    ? `${process.env.REACT_APP_URL_API}/${user.file_url}`
    : '/user.svg'

  const handleLogout = async () => {
    try {
      await api.delete('/users/logout')
    } catch (error) {
    } finally {
      const company = JSON.parse(localStorage.getItem('company') || '{}')

      logout(history, company?.slug)
    }
  }

    //Atualiza no bd a preferencia de linguagem ao logar.
    useEffect(() => {
      handleUpdateLanguagePreference();
    }, []); 

  return (
    <Topbar color={user.company?.primary_color}>
      <LeftSideContainer>
        <Link to="/">
          <Logo
            src={`${process.env.REACT_APP_URL_API}/${user.company?.logo_url}`}
            alt="Gkboard"
          />
        </Link>

        <NavigationContainer>
          {user.roles === 'admin' ? (
            <>
              <NavigationLink
                show
                style={{ display: 'block !important' }}
                to="/"
              >
                <FaIndustry fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.reports')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/usuarios">
                <FaUsers fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.users')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/empresas">
                <FaBusinessTime fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.companies')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/cards">
                <FaImages fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>	
                {translate('components.Topbar.cards')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/colecoes">
                <FaLayerGroup fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.groups')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/categorias">
                <FaObjectUngroup fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.categories')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/analytics">
                <FaChartArea fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.analytics')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink to="/capacidades">
                <FaDatabase fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.capabilities')}
                </NavigationText>
              </NavigationLink>
            </>
          ) : (
            <>
              <NavigationLink
                show
                style={{ display: 'block !important' }}
                to="/home"
              >
                <FaHome fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.home')}
                </NavigationText>
              </NavigationLink>

              <NavigationLink
                show
                style={{ display: 'block !important' }}
                to="/relatorios"
              >
                <FaIndustry fontSize={20} color={'#fff'} />
                <NavigationText style={{ color: '#fff' }}>
                {translate('components.Topbar.reports')}
                </NavigationText>
              </NavigationLink>
            </>
          )}
        </NavigationContainer>

        <ShowMobile>
          <Dropdown>
            <DropdownToggle color={user?.company.primary_color}>
              <AiOutlineMenu fontSize={20} color={'#fff'} />
              {/* <span style={{ color: "#fff", marginLeft: ".5rem" }}>Menu</span> */}
            </DropdownToggle>

            <DropdownMenu x="-60px" y="48px">
              {user.roles === 'admin' ? (
                <>
                  <DropdownItem onClick={() => history.push('/')}>
                    <FaIndustry fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.reports')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/usuarios')}>
                    <FaUsers fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.users')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/empresas')}>
                    <FaBusinessTime fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.companies')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/cards')}>
                    <FaImages fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.cards')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/colecoes')}>
                    <FaLayerGroup fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.groups')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/categorias')}>
                    <FaObjectUngroup fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.categories')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/analytics')}>
                    <FaChartArea fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.analytics')}
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/capacidades')}>
                    <FaDatabase fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.capabilities')}
                    </DropdownText>
                  </DropdownItem>
                </>
              ) : (
                <>
                  <DropdownItem onClick={() => history.push('/home')}>
                    <FaHome fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.home')} 
                    </DropdownText>
                  </DropdownItem>

                  <DropdownItem onClick={() => history.push('/relatorios')}>
                    <FaIndustry fontSize={20} color={'rgb(74,74,74)'} />
                    <DropdownText style={{ color: 'rgb(74,74,74)' }}>
                    {translate('components.Topbar.reports')}
                    </DropdownText>
                  </DropdownItem>
                </>
              )}
            </DropdownMenu>
          </Dropdown>
        </ShowMobile>
      </LeftSideContainer>

      <RightSideContainer>
        {isLoaded &&
          (user.view_report_options === 1 || user.roles === 'admin') && (
            <>
              <ActionsContainer>
                {/* <PersonalizedDropdown
                isLoading={isDownloading}
                text="Exportar"
                style={{
                  height: "23px",
                  marginRight: "1rem"
                }}
                btSize='10pt'
                items={[
                  {
                    text: "PNG",
                    onClick: () => handleExport({ format: "PNG", extension: "zip" })
                  },
                  {
                    text: "PDF",
                    onClick: () => handleExport({ format: "PDF", extension: "pdf" })
                  }
                ]}
              /> */}

                <BtnOrange
                  onClick={() => report.fullscreen()}
                  isLoading={false}
                  style={{
                    width: '23px',
                    height: '23px',
                    marginRight: '.5rem',
                    padding: 0,
                    backgroundColor: user.company?.secondary_color,
                  }}
                >
                  <img
                    src="/svgs/fullscreen.svg"
                    className="imageButtonReport"
                    alt={'icon'}
                  />
                </BtnOrange>

                <BtnOrange
                  onClick={() => report.print()}
                  isLoading={false}
                  style={{
                    width: '23px',
                    height: '23px',
                    marginRight: '.5rem',
                    padding: 0,
                    backgroundColor: user.company?.secondary_color,
                  }}
                >
                  <img
                    src="/svgs/print.svg"
                    className="imageButtonReport"
                    alt={'icon'}
                  />
                </BtnOrange>

                <BtnOrange
                  onClick={() => report.refresh()}
                  isLoading={false}
                  style={{
                    width: '23px',
                    height: '23px',
                    padding: 0,
                    backgroundColor: user.company?.secondary_color,
                  }}
                >
                  <img
                    src="/svgs/autorenew.svg"
                    className="imageButtonReport"
                    alt={'icon'}
                  />
                </BtnOrange>
              </ActionsContainer>

              <Divider style={{ backgroundColor: '#fff' }} />
            </>
          )}

<Dropdown>
  <DropdownToggle toggle={false}>
    <img
    src={`/svgs/${
      localStorage.getItem('language') === 'pt-BR'
        ? 'BR'
        : localStorage.getItem('language') === 'en'
        ? 'US'
        : localStorage.getItem('language') === 'es'
        ? 'ES'
        : localStorage.getItem('language') === 'ja'
        ? 'JP'
        : 'BR'
    }.svg`}
      alt="Idioma"
      style={{ width: '20px', height: '20px', borderRadius: '50%' }}
    />
  </DropdownToggle>
  <DropdownMenu>
    <DropdownItem
      onClick={() => {
        setLanguage('pt-BR');
        localStorage.setItem('language', 'pt-BR');
        handleUpdateLanguagePreference();
      }}
    >
      <img src="/svgs/BR.svg" alt="Português" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.portuguese')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('en');
        localStorage.setItem('language', 'en');
        handleUpdateLanguagePreference();
      }}
    >
      <img src="/svgs/US.svg" alt="Inglês" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.english')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('es');
        localStorage.setItem('language', 'es');
        handleUpdateLanguagePreference();
      }}
    >
      <img src="/svgs/ES.svg" alt="Espanhol" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.spanish')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('ja');
        localStorage.setItem('language', 'ja');
        handleUpdateLanguagePreference();
      }}
    >
      <img src="/svgs/JP.svg" alt="Japonês" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.japanese')}
    </DropdownItem>
  </DropdownMenu>
</Dropdown>


      <Divider style={{ backgroundColor: '#fff' }} />
        <Dropdown>
          <DropdownToggle color={'#fff'}>
            <UserContainer>
              <UserName style={{ color: '#fff' }}>{translate('components.Topbar.greeting')} {userName}!</UserName>
              <UserPhoto src={userImage} alt={`Foto de ${userName}`} />
            </UserContainer>
          </DropdownToggle>

          <DropdownMenu x="0px" y="58px">
            <DropdownItem onClick={() => history.push('/profile')}>
              <AiOutlineUser fontSize={20} color={'rgb(74,74,74)'} />
              <DropdownText style={{ color: 'rgb(74,74,74)' }}>
              {translate('components.Topbar.profile')}
              </DropdownText>
            </DropdownItem>

            <DropdownItem onClick={() => handleLogout(history)}>
              <BiLogOut fontSize={20} color={'rgb(74,74,74)'} />
              <DropdownText style={{ color: 'rgb(74,74,74)' }}>
              {translate('components.Topbar.logout')}
              </DropdownText>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>



      </RightSideContainer>
      {/* <TopbarMenuDesktop onClick={() => setToggle(!toggle)}>
        {!props.error && (
          <span style={{ marginLeft: toggle ? "200px" : "100px" }}><FaBars /></span>
        )}
      </TopbarMenuDesktop>
      <TopbarMenuMobile
        onClick={() => setToggle(!toggle)}
        className={toggle ? 'menu-open' : 'menu-closed'}
      >
        {!props.error && (
          <span><FaBars /></span>
        )}
      </TopbarMenuMobile>
      <TopbarActions>
        <div>
          <Logout onClick={() => handleLogout(history)}>Sair</Logout>
        </div>
      </TopbarActions> */}
    </Topbar>
  )
}

export default Component
