import { translate } from '../../utils/translate';  
const Validation = yup => ({
    email: yup.string()
        .email(translate('validations.AllValidations.validEmail'))
        .required(translate('validations.AllValidations.requiredField')),
    password: yup.string()
        .min(6, translate('validations.AllValidations.minLength'))
        .required(translate('validations.AllValidations.requiredField')),
})

export default Validation