import { translate } from '../../utils/translate';  

const Validation = yup => ({
    name: yup.string()
        .required(translate('validations.AllValidations.requiredField')),
    email: yup.string()
        .email(translate('validations.AllValidations.validEmail'))
        .required(translate('validations.AllValidations.requiredField')),
    contact: yup.string()
        .required(translate('validations.AllValidations.requiredField')),
    value: yup.string()
        .required(translate('validations.AllValidations.requiredField')),
})

export default Validation