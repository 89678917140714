import TitleBar from 'components/TitleBar'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import Content from 'components/Content'
import LinkBlue from 'components/Button/LinkBlue'
import SmLinkBlue from 'components/Button/SmLinkBlue'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/LabelSelect'
import BtnBlue from 'components/Button/BtnBlue'
import Checkbox from 'components/Form/LabelCheckbox'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import { FaEdit } from 'react-icons/fa'
import Td from 'components/Td'
import CardBody from 'components/CardBody'
import { phoneMask, moneyMask } from '../../utils/index'
import { useEffect, useState } from 'react'
import BtnBox from 'components/Button/BtnBox'

import { handleStatusByFirm } from 'services/api/status'
import { handleOriginsByFirm } from 'services/api/origins'
import { handleCompanies } from 'services/api/empresas'
import { translate } from '../../utils/translate';

const Component = props => {
  const [data, setData] = useState([])
  const [list, setList] = useState([])

  const [originData, setOriginData] = useState([])
  const [originList, setOriginList] = useState([])

  const [companiesData, setCompaniesData] = useState([])

  useEffect(() => {
    ;(async () => {
      setCompaniesData(await handleCompanies())
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setData(await handleStatusByFirm())
    })()
  }, [])

  useEffect(() => {
    setList(
      data.filter(item =>
        item.name
          .toUpperCase()
          .includes(props.formik.values.search.toUpperCase()),
      ),
    )
  }, [props.formik.values.search, data])

  useEffect(() => {
    ;(async () => {
      setOriginData(await handleOriginsByFirm())
    })()
  }, [])

  useEffect(() => {
    setOriginList(
      originData.filter(item =>
        item.name
          .toUpperCase()
          .includes(props.formik.values.searchOrigin.toUpperCase()),
      ),
    )
  }, [props.formik.values.searchOrigin, originData])

  return (
    <>
      <TitleBar label={translate('pages.UsersStore.users')} currentPage="" />
      <Content>
        <Card>
          <CardTitle title={translate('pages.UsersStore.create')}></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Input name="name" label={translate('pages.UsersStore.name')} formik={props.formik} />
                <Input name="email" label={translate('pages.UsersStore.email')} formik={props.formik} />
                <Input
                  name="contact"
                  label={translate('pages.UsersStore.contact')}
                  mask={phoneMask}
                  formik={props.formik}
                />
                <Input
                  name="value"
                  label={translate('pages.UsersStore.value')}
                  mask={moneyMask}
                  formik={props.formik}
                />
                <Select name="roles" label={translate('pages.UsersStore.permission')} formik={props.formik}>
                  <option value="">{translate('pages.UsersStore.select')}</option>
                  <option value="admin">{translate('pages.UsersStore.admin')}</option>
                  <option value="user">{translate('pages.UsersStore.user')}</option>
                </Select>
                <Select name="status_id" label={translate('pages.UsersStore.status')} formik={props.formik}>
                  <option value="">{translate('pages.UsersStore.select')}</option>
                  {data.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="origin_id" label={translate('pages.UsersStore.origin')} formik={props.formik}>
                  <option value="">{translate('pages.UsersStore.select')}</option>
                  {originData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Select name="company_id" label={translate('pages.UsersStore.company')} formik={props.formik}>
                  <option value="">{translate('pages.UsersStore.select')}</option>
                  {companiesData.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
                <Checkbox
                  name="send_mail"
                  label={translate('pages.UsersStore.sendEmail')}
                  formik={props.formik}
                  onChange={e => {
                    props.formik.setFieldValue(
                      'send_mail',
                      e.target.checked ? 1 : 0,
                    )
                  }}
                />
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                    {props.formik.isSubmitting ? <Spinner /> : translate('pages.UsersStore.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>

      <Content>
        <Card>
          <CardTitle title={translate('pages.UsersStore.origins')}>
            <LinkBlue to="/usuarios/cadastrar/origens/cadastrar">
              Cadastrar
            </LinkBlue>
          </CardTitle>

          <div style={{ padding: '15px 15px 0 15px' }}>
            <Input
              placeholder={translate('pages.UsersStore.search')}
              name="searchOrigin"
              label=""
              formik={props.formik}
            />
          </div>

          <Table>
            <thead>
              <tr>
                <th width="60">#</th>
                <th>{translate('pages.UsersStore.name')}</th>
                <th width="100">{translate('pages.UsersStore.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {originList.map((item, index) => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <td>{item.name}</td>
                  <Td>
                    <SmLinkBlue
                      to={`/usuarios/cadastrar/origens/${item.id}/editar`}
                    >
                      <FaEdit />
                    </SmLinkBlue>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>

      <Content>
        <Card>
          <CardTitle title={translate('pages.UsersStore.status')}>
            <LinkBlue to="/usuarios/cadastrar/status/cadastrar">
              Cadastrar
            </LinkBlue>
          </CardTitle>

          <div style={{ padding: '15px 15px 0 15px' }}>
            <Input
              placeholder={translate('pages.UsersStore.search')}
              name="search"
              label=""
              formik={props.formik}
            />
          </div>

          <Table>
            <thead>
              <tr>
                <th width="60">#</th>
                <th>{translate('pages.UsersStore.name')}</th>
                <th width="100">{translate('pages.UsersStore.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <td>{item.name}</td>
                  <Td>
                    <SmLinkBlue
                      to={`/usuarios/cadastrar/status/${item.id}/editar`}
                    >
                      <FaEdit />
                    </SmLinkBlue>
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>
    </>
  )
}

export default Component
