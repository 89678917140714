import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/LabelSelect'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { handleCategories } from 'services/api/categories'
import { handlePowerBiGroups, handlePowerBiReports } from 'services/api/powerbi'
import { handleReportCardsStore } from 'services/api/reportCards'
import * as Yup from 'yup'
import Validation from './validation'
import { translate } from '../../utils/translate';

const Page = () => {
  const [groups, setGroups] = useState([])
  const [reports, setReports] = useState([])
  const [categories, setCategories] = useState([])

  useEffect(() => {
    (async () => {
      setGroups(await handlePowerBiGroups())
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setCategories(await handleCategories())
    })()
  }, [])

  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const history = useHistory()
  const formik = useFormik({
    initialValues: { name: '', group_id: '', report_id: '', dataset_id: '', category_id: '', tipo: '0' },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () => handleReportCardsStore(history, formik.values, setMessages)
  })

  useEffect(() => {
    (async () => {
      if (formik.values.group_id) {
        setReports(await handlePowerBiReports(formik.values.group_id))
      }
    })()
  }, [formik.values.group_id])

  useEffect(() => {
    if (formik.values.report_id) {
      formik.setFieldValue('name', (reports.find(element => element.id === formik.values.report_id)).name)
      formik.setFieldValue('dataset_id', (reports.find(element => element.id === formik.values.report_id)).datasetId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.report_id])

  return (
    <>
      <TitleBar label="Cards" currentPage="" />
      <Content>
        <Card>
          <CardTitle title={translate('pages.CardsStore.create')}></CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Select name="tipo" label={translate('pages.CardsStore.type')} formik={formik}>
                  <option value={0}>{translate('pages.CardsStore.powerBI')}</option>
                  <option value={1}>{translate('pages.CardsStore.pageEditor')}</option>
                </Select>
                {formik.values.tipo === '0' && (
                  <>
                    <Select name="group_id" label={translate('pages.CardsStore.groups')} formik={formik}>
                      <option value="">{translate('pages.CardsStore.select')}</option>
                      {groups.map(group => <option key={group.id} value={group.id}>{group.name}</option>)}
                    </Select>
                    <Select name="report_id" label={translate('pages.CardsStore.reports')} formik={formik}>
                      <option value="">{translate('pages.CardsStore.select')}</option>
                      {reports.map(report => <option key={report.id} value={report.id}>{report.name}</option>)}
                    </Select>
                  </>
                )}
                <Select name="category_id" label={translate('pages.CardsStore.categories')} formik={formik}>
                  <option value="">{translate('pages.CardsStore.select')}</option>
                  {categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)}
                </Select>
                <Input name="name" label={translate('pages.CardsStore.name')} formik={formik} />
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : translate('pages.CardsStore.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Page
