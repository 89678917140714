import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { FaEdit } from "react-icons/fa"
import { useFormik } from 'formik'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import Input from 'components/Form/LabelInput'
import CardTitle from 'components/CardTitle'
import Table from 'components/Table'
import SmLinkBlue from 'components/Button/SmLinkBlue'

import Td from 'components/Td'

import api from 'services/api/api'
import { translate } from '../../../utils/translate';

export default function ReportsCollection() {
    const [reportsCollectionsItems, setReportsCollectionsItems] = useState([])
    const [reportsCollection, setReportsCollection] = useState({})
    const [list, setList] = useState([])
    
    const formik = useFormik({
        initialValues: {
        search: ''
        }
    })

    const params = useParams()

    useEffect(() => {
        (async () => {
          try {
            const { data } = await api.get(`/collections/${params.id}`)
  
            setReportsCollection(data)
          } catch(error) {}
        })()
      }, [params.id])

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get(`/collections/${params.id}/reports`)

                setReportsCollectionsItems(data)
            } catch(error) {

            }
          })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setList(reportsCollectionsItems.filter(item => item.report.name.toUpperCase().includes(formik.values.search.toUpperCase())))
    }, [formik.values.search, reportsCollectionsItems])

    return (
        <>
        <TitleBar label={translate('pages.ReportsCollectionReports.groups')} currentPage="" />

        <Content>
            <Card>
                <CardTitle title={translate('pages.ReportsCollectionReports.group')}></CardTitle>

                <Table>
                        <thead>
                        <tr>
                            <th>{translate('pages.ReportsCollectionReports.name')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{reportsCollection.name}</td>
                        </tr>
                        </tbody>
                    </Table>
            </Card>

            <Card>
                <CardTitle title={translate('pages.ReportsCollectionReports.reports')}></CardTitle>

                <div style={{ padding: "15px 15px 0 15px" }}>
                    <Input
                    placeholder={translate('pages.ReportsCollectionReports.search')}
                    name="search"
                    label=""
                    formik={formik}
                    />
                </div>

                <Table>
                    <thead>
                        <tr>
                            <th width="60">#</th>
                            <th>{translate('pages.ReportsCollectionReports.name')}</th>
                            <th>{translate('pages.ReportsCollectionReports.roles')}</th>
                            <th>{translate('pages.ReportsCollectionReports.enabledTabs')}</th>
                            <th width="100">{translate('pages.ReportsCollectionReports.actions')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map(reportsCollectionItem =>
                            <tr key={reportsCollectionItem.id}>
                                <td>{reportsCollectionItem.id}</td>
                                <td>{reportsCollectionItem.report.name}</td>
                                <td>{reportsCollectionItem.roles}</td>
                                <td>{reportsCollectionItem.page_navigation ? translate('pages.ReportsCollectionReports.yes') : translate('pages.ReportsCollectionReports.no')}</td>

                                <Td>
                                    <SmLinkBlue to={`/colecoes/${reportsCollection.id}/relatorios/${reportsCollectionItem.id}/editar`}>
                                        <FaEdit />
                                    </SmLinkBlue>
                                </Td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </Card>
        </Content>

    </>
    )
}