import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import BtnWhite from 'components/Button/BtnWhite'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import Content from 'components/Content'
import Select from 'components/Form/LabelSelect'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { format } from 'date-fns'
import { FieldArray, Form, Formik } from 'formik'
import DatePicker from 'react-datepicker'
import { FaTrash } from 'react-icons/fa'
import { handleUpdateOrCreatePowerBiCapacityIdleIntervals } from 'services/api/powerbi'
import * as Yup from 'yup'
import {
  IdleIntervalContainer,
  IdleIntervalItemContainer,
  IdleIntervalItemsContainer,
} from './styled'
import { idleIntervalsValidation } from './validation'
import { translate } from '../../utils/translate';

const Component = props => {
  return (
    <>
      <TitleBar
        label={`${translate('pages.CapacitiesOptimizeCosts.optimizeCapacity')} - ${props.id}`}
        currentPage=""
        showBack
        history={props.history}
      />
      <Content>
        <Card>
          <Content>
            <CardBody>
              <form onSubmit={props.formik.handleSubmit}>
                <Messages
                  formMessages={props.messages.messages}
                  alert={props.messages.alert}
                />
                <Select
                  name="optimize_cost"
                  label={translate('pages.CapacitiesOptimizeCosts.optimizeCosts')}
                  formik={props.formik}
                >
                  <option value="" hidden>
                  {translate('pages.CapacitiesOptimizeCosts.selectOption')}
                  </option>
                  <option value={1}>{translate('pages.CapacitiesOptimizeCosts.yes')}</option>
                  <option value={0}>{translate('pages.CapacitiesOptimizeCosts.no')}</option>
                </Select>
                {!!Number(props.formik.values.optimize_cost) && (
                  <>
                    <Select
                      name="idle_suspend_period"
                      label={translate('pages.CapacitiesOptimizeCosts.downtimePeriod')}
                      formik={props.formik}
                    >
                      <option value="" hidden>
                      {translate('pages.CapacitiesOptimizeCosts.selectOption')}
                      </option>
                      <option value={20}>20 {translate('pages.CapacitiesOptimizeCosts.minutes')}</option>
                      <option value={30}>30 {translate('pages.CapacitiesOptimizeCosts.minutes')}</option>
                      <option value={40}>40 {translate('pages.CapacitiesOptimizeCosts.minutes')}</option>
                      <option value={50}>50 {translate('pages.CapacitiesOptimizeCosts.minutes')}</option>
                      <option value={60}>60 {translate('pages.CapacitiesOptimizeCosts.minutes')}</option>
                    </Select>
                  </>
                )}
                <BtnBox>
                  <BtnBlue type="submit" disabled={props.formik.isSubmitting}>
                  {props.formik.isSubmitting ? <Spinner /> : translate('pages.CapacitiesOptimizeCosts.save')}
                  </BtnBlue>
                </BtnBox>
              </form>
              {!!Number(props.formik.values.optimize_cost) && (
                <>
                  <h2 style={{ marginTop: '20px' }}>{translate('pages.CapacitiesOptimizeCosts.intervals')}</h2>
                  <span>
                  {translate('pages.CapacitiesOptimizeCosts.createIntervals')}
                  </span>
                  <Formik
                    initialValues={{
                      idle_intervals: props.data.idle_intervals.length
                        ? props.data.idle_intervals
                        : [],
                    }}
                    onSubmit={async data => {
                      await handleUpdateOrCreatePowerBiCapacityIdleIntervals(
                        props.id,
                        data,
                        props.setMessages,
                      )
                    }}
                    validationSchema={Yup.object(idleIntervalsValidation(Yup))}
                    enableReinitialize
                  >
                    {({ values, setFieldValue, isSubmitting, errors }) => (
                      <Form>
                        <FieldArray name="idle_intervals">
                          {({ remove, push }) => (
                            <IdleIntervalContainer>
                              {values.idle_intervals.length
                                ? values.idle_intervals.map(
                                    (interval, index) => {
                                      let startIntervalDate = null
                                      let endIntervalDate = null

                                      if (interval.start_time) {
                                        startIntervalDate = new Date()

                                        const [startHour, startMinute] =
                                          interval.start_time.split(':')

                                        startIntervalDate.setHours(
                                          startHour,
                                          startMinute,
                                        )
                                      }

                                      if (interval.end_time) {
                                        endIntervalDate = new Date()
                                        const [endHour, endMinute] =
                                          interval.end_time.split(':')

                                        endIntervalDate.setHours(
                                          endHour,
                                          endMinute,
                                        )
                                      }

                                      return (
                                        <IdleIntervalItemsContainer key={index}>
                                          <IdleIntervalItemContainer>
                                            <DatePicker
                                              placeholderText={translate('pages.CapacitiesOptimizeCosts.startTime')}
                                              name={`idle_intervals.${index}.start_time`}
                                              onChange={e => {
                                                if (e) {
                                                  const timeFormatted = format(
                                                    e,
                                                    'HH:mm',
                                                  )

                                                  setFieldValue(
                                                    `idle_intervals.${index}.start_time`,
                                                    timeFormatted,
                                                  )
                                                }
                                              }}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              selected={startIntervalDate}
                                              dateFormat={'HH:mm'}
                                            />
                                            {errors.idle_intervals?.[index]
                                              ?.start_time && (
                                              <span>
                                                {
                                                  errors.idle_intervals[index]
                                                    .start_time
                                                }
                                              </span>
                                            )}
                                          </IdleIntervalItemContainer>

                                          <IdleIntervalItemContainer>
                                            <DatePicker
                                              placeholderText={translate('pages.CapacitiesOptimizeCosts.endTime')}
                                              name={`idle_intervals.${index}.end_time`}
                                              onChange={e => {
                                                const timeFormatted = format(
                                                  e,
                                                  'HH:mm',
                                                )

                                                setFieldValue(
                                                  `idle_intervals.${index}.end_time`,
                                                  timeFormatted,
                                                )
                                              }}
                                              showTimeSelect
                                              showTimeSelectOnly
                                              selected={endIntervalDate}
                                              dateFormat={'HH:mm'}
                                            />
                                            {errors.idle_intervals?.[index]
                                              ?.end_time && (
                                              <span>
                                                {
                                                  errors.idle_intervals[index]
                                                    .end_time
                                                }
                                              </span>
                                            )}
                                          </IdleIntervalItemContainer>

                                          <BtnWhite
                                            type="button"
                                            style={{
                                              padding: '5px 10px',
                                              fontSize: '14px',
                                              width: 'fit-content',
                                            }}
                                            onClick={() => {
                                              if (interval.id) {
                                                const idleIntervalsToRemove = [
                                                  ...(values.idle_intervals_to_remove
                                                    ? values.idle_intervals_to_remove
                                                    : []),
                                                  interval.id,
                                                ]
                                                setFieldValue(
                                                  'idle_intervals_to_remove',
                                                  idleIntervalsToRemove,
                                                )
                                              }
                                              remove(index)
                                            }}
                                          >
                                            <FaTrash />
                                          </BtnWhite>
                                        </IdleIntervalItemsContainer>
                                      )
                                    },
                                  )
                                : null}
                              <BtnBlue
                                style={{
                                  padding: '5px 10px',
                                  fontSize: '14px',
                                  marginTop: '10px',
                                  width: 'fit-content',
                                }}
                                onClick={() =>
                                  push({ start_time: '', end_time: '' })
                                }
                              >
                                {translate('pages.CapacitiesOptimizeCosts.addNewInterval')}
                              </BtnBlue>
                            </IdleIntervalContainer>
                          )}
                        </FieldArray>
                        <BtnBox>
                          <BtnBlue type="submit" disabled={isSubmitting}>
                            {isSubmitting ? <Spinner /> : translate('pages.CapacitiesOptimizeCosts.save')}
                          </BtnBlue>
                        </BtnBox>
                      </Form>
                    )}
                  </Formik>
                </>
              )}
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default Component
