import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import { Login, SubTitle, InputBox, BtnBox, Form } from './styled'
import { translate } from '../../utils/translate';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from './styled'
import { useState, useEffect } from 'react';

const Component = ({ formik, messages, data }) => {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'pt-BR');

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);
  return (
    <Login>
      <h1>{translate('pages.ForgotPassword.title')}</h1>
      <SubTitle>{translate('pages.ForgotPassword.instruction')}</SubTitle>
      <Form onSubmit={formik.handleSubmit}>
        <Messages formMessages={messages.messages} alert={messages.alert} />
        <InputBox>
          <Input
            labelColor="var(--color-fff)"
            type="email"
            name="email"
            label={translate('pages.ForgotPassword.emailLabel')}
            formik={formik}
          />
        </InputBox>
        <BtnBox>
          <BtnBlue style={{ backgroundColor: data ? data.secondary_color  : "var(--color-yellow)" }} type="submit" disabled={formik.isSubmitting}>
            {formik.isSubmitting ? <Spinner /> : translate('pages.ForgotPassword.submit')}
          </BtnBlue>
        </BtnBox>
      </Form>
      <Dropdown>
      <DropdownToggle toggle={false}>
  <img
    src={`/svgs/${
      localStorage.getItem('language') === 'pt-BR'
        ? 'BR'
        : localStorage.getItem('language') === 'en'
        ? 'US'
        : localStorage.getItem('language') === 'es'
        ? 'ES'
        : localStorage.getItem('language') === 'ja'
        ? 'JP'
        : 'BR'
    }.svg`}
    alt="Idioma"
    style={{ width: '20px', height: '20px', borderRadius: '50%' }}
  />
</DropdownToggle>

  <DropdownMenu>
    <DropdownItem
      onClick={() => {
        setLanguage('pt-BR')
        localStorage.setItem('language', 'pt-BR')
      }}
    >
      <img src="/svgs/BR.svg" alt="Português" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.portuguese')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('en')
        localStorage.setItem('language', 'en')
      }}
    >
      <img src="/svgs/US.svg" alt="Inglês" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.english')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('es')
        localStorage.setItem('language', 'es')
      }}
    >
      <img src="/svgs/ES.svg" alt="Espanhol" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.spanish')}
    </DropdownItem>
    <DropdownItem
      onClick={() => {
        setLanguage('ja')
        localStorage.setItem('language', 'ja')
      }}
    >
      <img src="/svgs/JP.svg" alt="Japonês" style={{ width: '20px', height: '20px', borderRadius: '50%' }} />
      {translate('components.Topbar.japanese')}
    </DropdownItem>
  </DropdownMenu>
</Dropdown>
    </Login>
  )
}

export default Component