import styled from 'styled-components'
import { CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';

export const Login = styled.div`
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;

  & h1 {
    font-size: 32px;
    padding: 10px 0;
    color: rgba(255, 255, 255, 1);
    text-align: start;
  }

  @media (max-width: 450px) {
    margin-bottom: 15px;
  }

  @media (max-width: 350px) {
    margin-bottom: 5px;
    padding: 0 .5rem;
  }

`
export const SubTitle = styled.p`
  color: rgba(255, 255, 255, .9);
  font-weight: bold;
  margin-bottom: 25px;

  @media (max-width: 450px) {
    margin-bottom: 15px;
  }
`
export const InputBox = styled.div`
  padding: 10px 0;
  max-width: 100%;

  @media (max-width: 450px) {
    padding: 5px 4px;
  }
`
export const BtnBox = styled.div`
  padding: 2rem 0;
  width: 100%;

  & button {
    width: 100%;
  }

  @media (max-width: 450px) {
    padding: .8rem;
  }
`
export const ForgotPassword = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  & a {
    text-decoration: none;
    color: var(--color-blue);
    font-weight: bold;
  }
`
export const Form = styled.form`
  width: 100%;

`

export const Dropdown = styled(CDropdown)``;

export const DropdownToggle = styled(CDropdownToggle)`
  background-color: transparent !important;
  border-radius: 30px;
  border: none;
  transition: all 0.3s;
  padding: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 18px;
  font-weight: bold;

  &:hover {
    opacity: 0.5;
  }

  &:focus {
    outline: 0;
  }

  @media (max-width: 600px) {
    font-size: 15px !important;
  }

  &.dropdown-toggle::after {
    color: ${(props) => props.color};
  }
`;

export const DropdownMenu = styled(CDropdownMenu)`
  transform: translate(${(props) => props.x}, ${(props) => props.y}) !important;
`;

export const DropdownItem = styled(CDropdownItem)`
  background-color: white !important;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;
