import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import { RiAddLine } from 'react-icons/ri'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import Select from 'components/Form/Select'
import IconButton from 'components/Button/IconButton'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import api from 'services/api/api'

import { handleCards } from 'services/api/reportCards'
import { handleUsersByRoles } from 'services/api/users'

import { setErrors } from 'utils'
import { translate } from '../../../utils/translate';

const validation = yup => ({
  name: yup.string().required(translate('validations.AllValidations.requiredField')),
  reports: yup.array().min(1, translate('validations.AllValidations.requiredField')),
  users: yup.array().min(1, translate('validations.AllValidations.requiredField')),
})

const NewReportCollection = () => {
  const [cards, setCards] = useState([])
  const [users, setUsers] = useState([])

  const [messages, setMessages] = useState({ messages: [], alert: '' })

  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      name: '',
      reports: [],
      users: [],
    },
    validationSchema: Yup.object(validation(Yup)),
    onSubmit: async () => {
      const reports = formik.values.reports.map(report => report.value)
      const users = formik.values.users.map(user => user.value)

      try {
        await api.post(`/collections`, {
          ...formik.values,
          reports,
          users,
        })

        history.push('/colecoes')
      } catch (error) {
        setMessages({ messages: setErrors(error), alert: 'alert-orange' })
      }
    },
  })

  useEffect(() => {
    ;(async () => {
      const cardsData = await handleCards()
      const usersData = await handleUsersByRoles('user')

      setCards(cardsData)
      setUsers(usersData)
    })()
  }, [])

  return (
    <>
      <TitleBar label={translate('pages.ReportsCollectionNew.groups')} currentPage="" />

      <Content>
        <Card>
          <CardTitle title={translate('pages.ReportsCollectionNew.create')}></CardTitle>

          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages
                  formMessages={messages.messages}
                  alert={messages.alert}
                />

                <Input name="name" label={translate('pages.ReportsCollectionNew.name')} formik={formik} />

                <Select
                  name="reports"
                  label={translate('pages.ReportsCollectionNew.reports')}
                  isMulti={true}
                  moreItems={
                    <div>
                      <IconButton
                        Icon={RiAddLine}
                        color="#142030"
                        text={translate('pages.ReportsCollectionNew.selectAll')}
                        onClick={() => {
                          formik.setFieldValue(
                            'reports',
                            cards.map(card => ({
                              value: card.id,
                              label: card.name,
                            })),
                          )
                        }}
                      />
                    </div>
                  }
                  options={cards.map(card => ({
                    value: card.id,
                    label: card.name,
                  }))}
                  formik={formik}
                />

                <Select
                  name="users"
                  label={translate('pages.ReportsCollectionNew.users')}
                  moreItems={
                    <div>
                      <IconButton
                        Icon={RiAddLine}
                        color="#142030"
                        text={translate('pages.ReportsCollectionNew.selectAll')}
                        onClick={() => {
                          formik.setFieldValue(
                            'users',
                            users.map(user => ({
                              value: user.id,
                              label: user.name,
                            })),
                          )
                        }}
                      />
                    </div>
                  }
                  isMulti={true}
                  options={users.map(user => ({
                    value: user.id,
                    label: user.name,
                  }))}
                  formik={formik}
                />

                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : translate('pages.ReportsCollectionNew.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>
      </Content>
    </>
  )
}

export default NewReportCollection
