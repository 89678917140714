import api from './api'
import { login, logout } from '../auth'
import { setErrors, removeMoneyMask } from 'utils'

export const handleUsers = async () =>
  (await api.post(`/users/list-by-firm/users`)).data

export const handleGeneratePassword = async id =>
  (await api.post(`/users/generate-password/${id}`)).data

export const handleUsersByRoles = async roles =>
  (await api.post(`/users/list-by-firm-and-roles`, { roles })).data

export const handleUserLogin = async (history, data, setMessages) => {
  const { email, password, aadToken, appName, appUrl, company } = data

  const uniqueId = crypto.randomUUID()

  try {
    const { token, user } = !aadToken
      ? (await api.post(`/users/login`, { email, password, uniqueId, company }))
          .data
      : (
          await api.post(`/users/login/microsoft`, {
            aadToken,
            appName,
            appUrl,
          })
        ).data

    localStorage.setItem('company', JSON.stringify(user.company))
    login(token, uniqueId)

    if (user.roles === 'admin') {
      return history.push(`/grupos`)
    }

    return history.push(`/home`)
  } catch (error) {
    if (error.response) {
      setMessages({
        messages: setErrors(error) || 'KKk',
        alert: 'alert-orange',
      })
      return
    }

    setMessages({
      messages: 'Sistema inativo no momento!',
      alert: 'alert-orange',
    })
  }
}

export const handleUserForgotPassword = async (data, setMessages) => {
  const values = {
    emailTo: data.email,
    forgotPasswordPageUrl: data.data
      ? `${process.env.REACT_APP_URL_APP}/${data.data.slug}/recuperar-senha`
      : `${process.env.REACT_APP_URL_APP}/recuperar-senha`,
  }
  try {
    const response = await api.post(`/users/forgot-password`, values)
    setMessages({ messages: response.data.data.message, alert: 'alert-green' })
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUserRecoverPassword = async (
  history,
  token,
  data,
  setMessages,
  company,
) => {
  try {
    await api.put(`/users/recover-password/${token}`, data)
    return history.push(company ? `/${company.slug}` : '/login')
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleStoreUser = async (history, data, setMessages) => {
  const values = {
    name: data.name,
    email: data.email,
    roles: data.roles,
    appName: 'GKBOARD',
    appUrl: process.env.REACT_APP_URL_APP,
    contact: data.contact,
    value: removeMoneyMask(data.value),
    status_id: data.status_id === '' ? null : data.status_id,
    origin_id: data.origin_id === '' ? null : data.origin_id,
    company_id: data.company_id === '' ? null : data.company_id,
    send_mail: data.send_mail,
  }

  try {
    await api.post(`/users/store-auth-user`, values)
    return history.push(`/usuarios`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateUser = async (history, data, userId, setMessages) => {
  if (data.status_id === '') delete data.status_id
  if (data.origin_id === '') delete data.origin_id
  if (data.company_id === '') delete data.company_id

  try {
    await api.put(`/users/${userId}/update-auth-user`, {
      ...data,
      value: removeMoneyMask(data.value),
    })
    return history.push(`/usuarios`)
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateViewReportOptions = async (data, userId) => {
  await api.put(`/users/${userId}/view-report-options`, data)
}

export const handleUpdateRestrictedLogin = async (data, userId) => {
  await api.put(`/users/${userId}/restricted-Login`, data)
}

export const handleUserUpdateFile = async (data, id) => {
  const values = new FormData()
  values.append('file', data)
  try {
    await api.put(`/users/${id}/upload`, values)
    return
  } catch (error) {
    return error
  }
}

export const handleUserUpdatePassword = async (history, data, setMessages) => {
  try {
    await api.put(`/users/update-password`, data)
    logout(history)
    return
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateProfile = async (
  history,
  profileId,
  data,
  setMessages,
) => {
  const values = {
    name: data.name,
    email: data.email
  }

  try {
    await api.put(`/users`, values)
    logout(history)
    return
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUpdateLanguagePreference = async (

  setMessages
) => {
  const languagePreference = localStorage.getItem('language')
  if (!languagePreference) {
    setMessages({
      messages: ['Idioma não encontrado no localStorage'],
      alert: 'alert-orange'
    })
    return
  }
  const values = {

    language_preference: languagePreference
  }
  try {
    await api.put(`/users/userlang`, values)
    return
  } catch (error) {
    setMessages({ messages: setErrors(error), alert: 'alert-orange' })
  }
}

export const handleUserShow = async id => (await api.get(`/users/${id}`)).data

export const handleUserDelete = async (id, history) => {
  await api.delete(`/users/${id}`)
  return history.push('/usuarios')
}
