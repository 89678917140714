import TitleBar from "components/TitleBar";
import Content from "components/Content";
import Card from "components/Card";
import CardTitle from "components/CardTitle";
import Table from "components/Table";
import LinkBlue from "components/Button/LinkBlue";
import SmLinkBlue from "components/Button/SmLinkBlue";
import { FaEdit, FaChartArea, FaCopy } from "react-icons/fa";
import { GiSettingsKnobs } from "react-icons/gi";
import Input from 'components/Form/LabelInput'
import { useFormik } from 'formik'
import Td from "components/Td";
import { useEffect, useState } from "react";
import { translate } from '../../utils/translate';

const Component = (props) => {
  const [list, setList] = useState([])
  const [show, setShow] = useState([])
  
  useEffect(() => {
    setShow(Array(props.data.length).fill(false))
  }, [props.data])

  console.log(show)

  const formik = useFormik({
    initialValues: {
      search: ''
    }
  })

  useEffect(() => {
    setList(props.data.filter(item => item.name.toUpperCase().includes(formik.values.search.toUpperCase())))
}, [formik.values.search, props.data])

  return (
    <>
      <TitleBar label={translate('pages.Users.users')} currentPage="" />
      <Content>
        <Card>
          <CardTitle title={translate('pages.Users.list')}>
            <LinkBlue to="/usuarios/cadastrar">{translate('pages.Users.create')}</LinkBlue>
          </CardTitle>

          <div style={{ padding: "15px 15px 0 15px" }}>
            <Input
              placeholder={translate('pages.Users.search')}
              name="search"
              label=""
              formik={formik}
            />
          </div>
          
          <Table>
            <thead>
              <tr>
                <th width="60">#</th>
                <th>{translate('pages.Users.name')}</th>
                <th>{translate('pages.Users.email')}</th>
                <th>{translate('pages.Users.permission')}</th>
                <th width="100">{translate('pages.Users.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {list.map((item, index) => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.roles}</td>
                  <Td>
                    <SmLinkBlue to={`#`}>
                      <FaCopy onClick={() => navigator.clipboard.writeText(list[index].password ? list[index].password : translate('pages.Users.noPassword'))} />
                    </SmLinkBlue>
                    <SmLinkBlue to={`/usuarios/${item.id}/editar`}>
                      <FaEdit />
                    </SmLinkBlue>
                    {item.roles === "user" && (
                      <SmLinkBlue to={`/usuarios/${item.id}/relatorios`}>
                        <FaChartArea />
                      </SmLinkBlue>
                    )}
                    {item.roles === "user" && (
                      <SmLinkBlue to={`/usuarios/${item.id}/acessos`}>
                        <GiSettingsKnobs />
                      </SmLinkBlue>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>
    </>
  );
};

export default Component;
