import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import Upload from 'components/Form/Upload'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import BtnOrange from 'components/Button/BtnOrange'
import CardBody from 'components/CardBody'
import BtnRed from 'components/Button/BtnRed'
import BtnBox from 'components/Button/BtnBox'
import ColorPicker from 'components/Form/ColorPicker'
import BtnWhite from 'components/Button/BtnWhite'
import Modal from 'components/Modal'

import {
  handleCompanieshow,
  handleCompanyUpdate,
  handleCompanyDelete,
  handleCompanyUpdateLogo,
  handleCompanyUpdateBackground,
} from 'services/api/empresas'

import { slugMask } from 'utils'
import { translate } from '../../../utils/translate';

const validation = yup => ({
  name: yup.string().required(translate('validations.AllValidations.requiredField')),
  slug: yup.string().required(translate('validations.AllValidations.requiredField')),
  email: yup.string().required(translate('validations.AllValidations.requiredField')),
})

const NewReportCollection = () => {
  const [data, setData] = useState({})

  const [visibleModal, setVisibleModal] = useState(false)
  const [messages, setMessages] = useState({ messages: [], alert: '' })

  const [messagesFile, setMessagesFile] = useState('')
  const [done, setDone] = useState(true)
  const [errors, setErrors] = useState([])

  const history = useHistory()
  const params = useParams()

  const formik = useFormik({
    initialValues: {
      name: data.name,
      slug: data.slug,
      email: data.email,
      primary_color: data.primary_color,
      secondary_color: data.secondary_color,
    },
    enableReinitialize: true,
    validationSchema: Yup.object(validation(Yup)),
    onSubmit: async () =>
      handleCompanyUpdate(history, formik.values, params.id, setMessages),
  })

  useEffect(() => {
    ;(async () => {
      try {
        setDone(true)
        setData(await handleCompanieshow(params.id))
        setDone(false)
      } catch (error) {}
    })()
  }, [params.id])

  const handleDelete = async () => handleCompanyDelete(params.id, history)

  const files = async files => {
    setMessagesFile('')
    setErrors([])
    setDone(true)
    await handleCompanyUpdateLogo(files[0], params.id)
    setData(await handleCompanieshow(params.id))
    setDone(false)
  }

  const filesFundo = async files => {
    setMessagesFile('')
    setErrors([])
    setDone(true)
    await handleCompanyUpdateBackground(files[0], params.id)
    setData(await handleCompanieshow(params.id))
    setDone(false)
  }

  return (
    <>
      <TitleBar label={translate('pages.CompaniesUpdate.companies')} currentPage="" />

      <Content>
        <Card>
          <CardTitle title={translate('pages.CompaniesUpdate.edit')}>
            <BtnOrange onClick={() => setVisibleModal(true)}>{translate('pages.CompaniesUpdate.delete')}</BtnOrange>
          </CardTitle>

          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages
                  formMessages={messages.messages}
                  alert={messages.alert}
                />

                <Input name="name" label={translate('pages.CompaniesUpdate.name')} formik={formik} />
                <Input
                  mask={slugMask}
                  name="slug"
                  label={translate('pages.CompaniesUpdate.slug')}
                  formik={formik}
                />
                <Input name="email" label={translate('pages.CompaniesUpdate.email')} formik={formik} />
                <ColorPicker
                  name="primary_color"
                  label={translate('pages.CompaniesUpdate.primaryColor')}
                  formik={formik}
                />
                <ColorPicker
                  name="secondary_color"
                  label={translate('pages.CompaniesUpdate.secondaryColor')}
                  formik={formik}
                />

                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : translate('pages.CompaniesUpdate.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>

        <Card>
          <CardTitle title="Logo"></CardTitle>
          <Content>
            <CardBody>
              <Upload
                onUpload={files}
                fileUrl={data.logo_url}
                done={done}
                errors={errors}
                setErrors={setErrors}
              />
              <p>{messagesFile}</p>
            </CardBody>
          </Content>
        </Card>

        <Card>
          <CardTitle title={translate('pages.CompaniesUpdate.background')}></CardTitle>
          <Content>
            <CardBody>
              <Upload
                onUpload={filesFundo}
                fileUrl={data.background_url}
                done={done}
                errors={errors}
                setErrors={setErrors}
              />
              <p>{messagesFile}</p>
            </CardBody>
          </Content>
        </Card>
      </Content>

      <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>{translate('pages.CompaniesUpdate.confirmDelete')}</p>

                <BtnBox>
                  <BtnRed onClick={handleDelete}>{translate('pages.CompaniesUpdate.delete')}</BtnRed>
                  <BtnWhite onClick={() => setVisibleModal(false)}>
                    Cancelar
                  </BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default NewReportCollection
