import { translate } from '../../utils/translate';  

const Validation =  yup => ({
    password: yup.string()
        .min(6, translate('validations.AllValidations.minLength'))
        .required(translate('validations.AllValidations.requiredField')),
    password_confirmation: yup.string()
        .required(translate('validations.AllValidations.requiredField'))
        .oneOf([yup.ref('password'), null], translate('validations.AllValidations.passwordConfirmation'))
})

export default Validation