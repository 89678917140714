import SmLinkBlue from 'components/Button/SmLinkBlue'
import { BtnBlue } from 'components/Button/styled'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import { Spinner } from 'components/Spinner/styled'
import Table from 'components/Table'
import { TdWithTextLimit } from 'components/Table/styled'
import Td from 'components/Td'
import TitleBar from 'components/TitleBar'
import { useEffect, useState } from 'react'
import { FaCoins, FaEdit, FaPause, FaPlay } from 'react-icons/fa'
import {
  handleRefreshPowerBiCapacities,
  handleResumePowerBiCapacity,
  handleSuspendPowerBiCapacity,
} from 'services/api/powerbi'
import { OverlayDiv } from './styled'
import { translate } from '../../utils/translate';

const Component = props => {
  const [list, setList] = useState([])
  const [disableActions, setDisableActions] = useState(false)
  const [refreshingCapacities, setRefreshingCapacities] = useState(false)

  useEffect(() => {
    setList(props.data)
  }, [props.data])

  async function handleWithResumeOrSuspendCapacity(capacityId, action) {
    try {
      setDisableActions(true)

      const capacity =
        action === 'resume'
          ? await handleResumePowerBiCapacity(capacityId)
          : await handleSuspendPowerBiCapacity(capacityId)

      setList(prev => {
        const getActualCapacity = prev.findIndex(
          capacity => capacity.id === capacityId,
        )

        prev[getActualCapacity] = { ...capacity }

        return prev
      })
    } catch (error) {
      console.log(error)
    } finally {
      setDisableActions(false)
    }
  }

  async function handleWithRefreshCapacities() {
    try {
      setRefreshingCapacities(true)

      const capacities = await handleRefreshPowerBiCapacities()

      setList(prev => {
        const capacitiesMapped = [...prev, ...capacities].reduce(
          (acc, current) => {
            const getExistentIndex = acc.findIndex(
              capacity => capacity.id === current.id,
            )

            if (getExistentIndex === -1) {
              acc.push({
                ...acc[getExistentIndex],
                ...current,
              })
            } else {
              acc[getExistentIndex] = {
                ...acc[getExistentIndex],
                ...current,
              }
            }

            return acc
          },
          [],
        )

        return capacitiesMapped
      })
    } catch (error) {
      console.log(error)
    } finally {
      setRefreshingCapacities(false)
    }
  }

  return (
    <>
      <TitleBar label={translate('pages.Capacities.capabilities')} currentPage="" />
      <Content>
        <Card>
          {refreshingCapacities && (
            <OverlayDiv>
              <Spinner />
            </OverlayDiv>
          )}
          <CardTitle title={translate('pages.Capacities.listing')}>
            <BtnBlue
              onClick={() => {
                handleWithRefreshCapacities()
              }}
              disabled={refreshingCapacities}
            >
              {translate('pages.Capacities.updateCapabilities')}
            </BtnBlue>
          </CardTitle>

          <Table limitTextSize>
            <thead>
              <tr>
                <th>#</th>
                <th>{translate('pages.Capacities.name')}</th>
                <th>{translate('pages.Capacities.sku')}</th>
                <th>{translate('pages.Capacities.status')}</th>
                <th>{translate('pages.Capacities.costOptimizationActive')}</th>
                <th>{translate('pages.Capacities.downtimeMinutes')}</th>
                <th>{translate('pages.Capacities.actions')}</th>
              </tr>
            </thead>
            <tbody>
              {list.map(item => (
                <tr key={item.id}>
                  <td>{`#${item.id}`}</td>
                  <TdWithTextLimit maxWidth={250} title={item.name}>
                    {item.name}
                  </TdWithTextLimit>
                  <td>{item.sku}</td>
                  <td>{item.state === 'Active' ? 'Ativa' : 'Suspensa'}</td>
                  <td>{item.optimize_cost ? 'Sim' : 'Não'}</td>
                  <td>{item.idle_suspend_period}</td>
                  <Td>
                    <SmLinkBlue
                      to={`/capacidades/${item.id}/editar`}
                      title={translate('pages.Capacities.editCapability')}
                    >
                      <FaEdit />
                    </SmLinkBlue>
                    {item.resource_group && item.subscription_id && (
                      <>
                        <SmLinkBlue
                          to={`/capacidades/${item.id}/otimizar-custos`}
                          title={translate('pages.Capacities.optimizeCosts')}
                        >
                          <FaCoins />
                        </SmLinkBlue>
                        {item.state === 'Active' ? (
                          <BtnBlue
                            title={translate('pages.Capacities.pauseCapability')}
                            disabled={disableActions}
                            style={{ padding: '5px 25px' }}
                            onClick={() => {
                              handleWithResumeOrSuspendCapacity(
                                item.id,
                                'suspend',
                              )
                            }}
                          >
                            <FaPause />
                          </BtnBlue>
                        ) : (
                          <BtnBlue
                            title={translate('pages.Capacities.startCapability')}
                            disabled={disableActions}
                            style={{ padding: '5px 25px' }}
                            onClick={() => {
                              handleWithResumeOrSuspendCapacity(
                                item.id,
                                'resume',
                              )
                            }}
                          >
                            <FaPlay />
                          </BtnBlue>
                        )}
                      </>
                    )}
                  </Td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      </Content>
    </>
  )
}

export default Component
