import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'

import * as Yup from 'yup'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import Input from 'components/Form/LabelInput'
import BtnBlue from 'components/Button/BtnBlue'
import ColorPicker from 'components/Form/ColorPicker'
import Messages from 'components/Messages'
import Spinner from 'components/Spinner'
import CardBody from 'components/CardBody'
import BtnBox from 'components/Button/BtnBox'

import { handleCompaniesStore } from 'services/api/empresas'

import { slugMask } from 'utils'
import { translate } from '../../../utils/translate';

const validation = yup => ({
    name: yup.string()
        .required(translate('validations.AllValidations.requiredField')),
    slug: yup.string()
      .required(translate('validations.AllValidations.requiredField')),
    email: yup.string()
      .required(translate('validations.AllValidations.requiredField'))
})

const NewReportCollection = () => {
    const [messages, setMessages] = useState({ messages: [], alert: '' })

    const history = useHistory()

    const formik = useFormik({
      initialValues: { 
        name: '', 
        slug: '', 
        email: '', 
        primary_color: '#ffffff', 
        secondary_color: '#ffffff'
      },
      validationSchema: Yup.object(validation(Yup)),
      onSubmit: async () => handleCompaniesStore(history, formik.values, setMessages)
    })

    return (
        <>
        <TitleBar label={translate('pages.CompaniesNew.companies')} currentPage="" />

        <Content>
          <Card>
            <CardTitle title={translate('pages.CompaniesNew.create')}></CardTitle>

            <Content>
              <CardBody>
                <form onSubmit={formik.handleSubmit}>
                  <Messages formMessages={messages.messages} alert={messages.alert} />

                  <Input name="name" label={translate('pages.CompaniesNew.name')} formik={formik} />
                  <Input mask={slugMask} name="slug" label={translate('pages.CompaniesNew.slug')} formik={formik} />
                  <Input name="email" label={translate('pages.CompaniesNew.email')} formik={formik} />

                  <ColorPicker name="primary_color" label={translate('pages.CompaniesNew.primaryColor')} formik={formik} />
                  <ColorPicker name="secondary_color" label={translate('pages.CompaniesNew.secondaryColor')} formik={formik} />

                  <BtnBox>
                    <BtnBlue type="submit" disabled={formik.isSubmitting}>
                      {formik.isSubmitting ? <Spinner /> : translate('pages.CompaniesNew.submit')}
                    </BtnBlue>
                  </BtnBox>
                </form>
              </CardBody>
            </Content>
          </Card>
        </Content>
      </>
    )
}

export default NewReportCollection