import { useEffect, useState } from 'react'
import { useFormik } from 'formik'

import TitleBar from 'components/TitleBar'
import Content from 'components/Content'
import Card from 'components/Card'
import CardTitle from 'components/CardTitle'
import LinkBlue from 'components/Button/LinkBlue'
import Input from 'components/Form/LabelInput'
import Container from 'components/Container'
import ReportCard from 'components/ReportCard'

import { handleCategoriesByFirm } from 'services/api/categories'
import { translate } from '../../utils/translate';

const Page = () => {
  const [data, setData] = useState([])
  const [list, setList] = useState([])

  const formik = useFormik({
    initialValues: {
      search: ''
    }
  })

  useEffect(() => {
    (async () => {
      setData(await handleCategoriesByFirm())
    })()
  }, [])

  useEffect(() => {
    setList(data.filter(item => item.name.toUpperCase().includes(formik.values.search.toUpperCase())))
}, [formik.values.search, data])

  return (
    <>
      <TitleBar label={translate('pages.Categories.categories')} currentPage="" />

      <Content>
        <Card>
          <CardTitle title={translate('pages.Categories.list')}>
            <LinkBlue to="/categorias/cadastrar">{translate('pages.Categories.create')}</LinkBlue>
          </CardTitle>

          <div style={{ padding: "15px 15px 0 15px" }}>
            <Input
              placeholder={translate('pages.Categories.search')}
              name="search"
              label=""
              formik={formik}
            />
          </div>
          
          <Container>
            {list.map(item => <ReportCard key={item.id} name={item.name} fileUrl={item.file_url} linkTo={`/categorias/${item.id}/editar`} />)}
          </Container>
        </Card>
      </Content>
    </>
  )
}

export default Page
