import BtnBlue from 'components/Button/BtnBlue'
import BtnBox from 'components/Button/BtnBox'
import BtnOrange from 'components/Button/BtnOrange'
import BtnRed from 'components/Button/BtnRed'
import BtnWhite from 'components/Button/BtnWhite'
import Card from 'components/Card'
import CardBody from 'components/CardBody'
import CardTitle from 'components/CardTitle'
import Content from 'components/Content'
import Input from 'components/Form/LabelInput'
import Messages from 'components/Messages'
import Modal from 'components/Modal'
import Spinner from 'components/Spinner'
import TitleBar from 'components/TitleBar'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { handleOriginsDelete, handleOriginsUpdate, handleOriginshow } from 'services/api/origins'
import * as Yup from 'yup'
import Validation from './validation'
import { translate } from '../../utils/translate';

const Page = () => {

  const history = useHistory()
  const params = useParams()
  const [messages, setMessages] = useState({ messages: [], alert: '' })
  const [data, setData] = useState({})
  const [visibleModal, setVisibleModal] = useState(false)

  useEffect(() => {
    (async () => {
      setData(await handleOriginshow(params.id))
    })()
  }, [params.id])

  const formik = useFormik({
    initialValues: {
      name: data.name
    },
    validationSchema: Yup.object(Validation(Yup)),
    onSubmit: () => handleOriginsUpdate(history, formik.values, params.id, setMessages),
    enableReinitialize: true
  })

  const handleDelete = async () => await handleOriginsDelete(params.id, history)

  return (
    <>
      <TitleBar label={translate('pages.OriginsUpdate.origins')} currentPage="" />
      <Content>
        <Card>
          <CardTitle title={translate('pages.OriginsUpdate.edit')}>
            <BtnOrange onClick={() => setVisibleModal(true)}>{translate('pages.OriginsUpdate.delete')}</BtnOrange>
          </CardTitle>
          <Content>
            <CardBody>
              <form onSubmit={formik.handleSubmit}>
                <Messages formMessages={messages.messages} alert={messages.alert} />
                <Input name="name" label={translate('pages.OriginsUpdate.name')} formik={formik} />
                <BtnBox>
                  <BtnBlue type="submit" disabled={formik.isSubmitting}>
                    {formik.isSubmitting ? <Spinner /> : translate('pages.OriginsUpdate.submit')}
                  </BtnBlue>
                </BtnBox>
              </form>
            </CardBody>
          </Content>
        </Card>

      </Content>
      <Modal visibleModal={visibleModal} setVisibleModal={setVisibleModal}>
        <Card>
          <Content>
            <Content>
              <CardBody>
                <p>{translate('pages.OriginsUpdate.confirmDelete')}</p>
                <BtnBox>
                  <BtnRed onClick={handleDelete}>{translate('pages.OriginsUpdate.delete')}</BtnRed>
                  <BtnWhite onClick={() => setVisibleModal(false)}>{translate('pages.OriginsUpdate.cancel')}</BtnWhite>
                </BtnBox>
              </CardBody>
            </Content>
          </Content>
        </Card>
      </Modal>
    </>
  )
}

export default Page
